<template>
  <div v-if="visible">
    <div style="width:100%; background-color:#5A8BA9;padding: 15px;color: #fff; ">
      <div>目前目前贵单位参与的认证类型是：<span>{{ type }}</span>；<span style="padding-left: 40px;color: red;cursor:pointer" @click="reselect" >(认证类型重新选择)</span></div>
      <div v-if="isTypeDisplay">
        <el-row>
          <el-col :span="12">
            首选再灌注策略 :
            <el-radio-group v-model="attestation.fistMeasure">
              <el-radio label="THROM">溶栓</el-radio>
              <el-radio label="TRANSPORT">转运PPCI</el-radio>
              <el-radio label="HOSPITAL">本院PPCI</el-radio>
            </el-radio-group>
          </el-col>
          <el-col :span="12">
            <el-row>
              <el-col :span="6"> 次选再灌注策略:</el-col>
              <el-col :span="18">
                <el-checkbox-group v-model="attestation.measure">
                  <el-checkbox label="HOSPITAL">本院PPCI</el-checkbox>
                  <el-checkbox label="THROM">溶栓</el-checkbox>
                  <el-checkbox label="TRANSPORT">转运PPCI</el-checkbox>
                </el-checkbox-group>
              </el-col>
            </el-row>


          </el-col>
        </el-row>
      </div>
      <div>
        当前共 163 条自评项，共选择 0 条选项， 选择满足项：0 条，选择不满足项 0 条
      </div>
      <div>
        不能发起认证，还差 163 条未满足
      </div>
    </div>
    <div style="margin-top: 50px;">
      <div style="height: 30px;padding-top:5px;background-color: #5A8BA9;text-align:center;color: #fff;">已上传文件列表</div>
      <el-table class="mb-20" :data="fileList" row-key="id" border empty-text="暂无信息.">
        <el-table-column prop="typeName" label="文件类型" width="150"/>
        <el-table-column prop="name" label="文件名称" min-width="250"/>
        <el-table-column prop="time" label="上传时间" min-width="200"/>
        <el-table-column  label="操作" width="200" align="center">
          <template #default="scope">
            <el-button type="danger" @click="handleview(scope.row.url)">查看</el-button>
            <el-button type="danger" @click="handleDel(scope.row.oid)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-form
          ref="ruleFormRef"
          label-width="120px"
          class="flex-1"
          status-icon
          :model="ruleForm"
      >
        <el-form-item label="请上传承诺函" prop="certificates01">
          <div class="left-float-top">
            <el-upload
                ref="upload01"
                class="mr-20 center-float-left"
                :action="basicUrl+'/api/dmz/ATTESTATION/file/upload'"
                :limit="1"
                :show-file-list="false"
                :on-success="handleExceed1"
                :before-upload="beforeAvatarUpload1"
            >
              <template #trigger>
                <div class="center-float-left">
                  <el-input class="mr-10" v-model="upload201.name" disabled></el-input>
                  <el-button type="primary">选择文件</el-button>
                </div>
              </template>
            </el-upload>
            <el-button type="primary" @click="submitUpload1">上 传</el-button>
          </div>
        </el-form-item>
        <el-form-item label="请上传任命书" prop="certificates02">
          <div class="left-float-top">
            <el-upload
                ref="upload02"
                class="mr-20 center-float-left"
                :action="basicUrl+'/api/dmz/ATTESTATION/file/upload'"
                :limit="1"
                :show-file-list="false"
                :on-success="handleExceed2"
                :before-upload="beforeAvatarUpload2"
            >
              <template #trigger>
                <div class="center-float-left">
                  <el-input class="mr-10" v-model="upload202.name" disabled></el-input>
                  <el-button type="primary">选择文件</el-button>
                </div>
              </template>
            </el-upload>
            <el-button type="primary" @click="submitUpload2">上 传</el-button>
          </div>
        </el-form-item>
        <el-collapse class="certificate-collapse" v-model="collapse">
          <el-collapse-item title="要素一 基本条件与资质" name="1">
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">胸痛中心的组织机构</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">医院发布正式文件成立胸痛中心及胸痛中心委员会，要求：</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">1.10 由医院院长或分管医疗的副院长担任胸痛中心委员会主任委员，主持胸痛中心委员会的工作和重大决策</div>
                    <div class="line"></div>
                    <div style="width: 75%;">书面文件明确胸痛中心委员会的工作职责</div>
                    <div class="line"></div>
                    <div style="width: 75%;">明确胸痛中心委员会具有调动医院所有资源为胸痛中心建设和运行提供保障的权力</div>
                    <div class="line"></div>
                    <div style="width: 75%;"> 胸痛中心成立并实际运作至少6个月以上才能申请认证</div>
                    <div class="line"></div>
                  </div>

                  <div style="margin-top: 15px;">
                    <div>
                      <span class="text-title">任命胸痛中心医疗总监和行政总监,要求：</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div style="width: 75%;">1.11
                      医院正式任命一名具有心血管内科专业或急诊专业背景、中级以上职称的医师担任胸痛中心医疗总监，且该医师应具备较强的组织协调能力，专业技能必须具备对急性冠状动脉综合征（ACS）、急性主动脉夹层、肺动脉栓塞等急性胸痛患者进行诊断和早期急救的能力
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">医院应任命一名从事急诊或医疗行政管理工作、且能有效调动院内各部门资源的人员担任胸痛中心行政总监，负责胸痛中心的行政管理和资源协调工作</div>
                    <div class="line"></div>
                    <div style="width: 75%;">书面文件正式明确胸痛中心医疗总监和行政总监的职责</div>
                    <div class="line"></div>
                  </div>
                  <div style="margin-top: 15px;">
                    <div>
                      <span class="text-title">任命胸痛中心协调员，要求</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">1.12 指定一名具有急诊或心血管内科专业背景的医师担任胸痛中心协调员，必须具备正确处理ACS及其它急性胸痛的能力</div>
                    <div class="line"></div>
                    <div style="width: 75%;">书面文件明确协调员的具体工作职责</div>
                    <div class="line"></div>
                    <div style="width: 75%;">协调员每年至少参加ACS和胸痛中心相关的培训不少于10学时</div>
                    <div class="line"></div>
                  </div>
                  <div style="margin-top: 15px;">
                    <div>
                      <div style="width: 75%;float: left;" class="text-title">1.13
                        明确胸痛中心质量控制机制及责任人，根据医院实际情况可以由医院质量管理部门承担或者由胸痛中心协调员、二级以上数据审核员兼任
                      </div>
                      <div style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">医院对胸痛中心的支持与承诺</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">胸痛中心建设需要医院的大力支持，医院在成立胸痛中心时应发布正式文件做出全力支持胸痛中心建设的承诺</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div style="width: 75%;">1.14 全力支持胸痛中心的建设与认证，承诺分配相应人力、设备和财政资源，并做好监察、考核、质量控制等工作，确保胸痛中心规范化运行；<br>
                      对胸痛中心在优化诊疗流程过程中所涉及到的院内外标识与指引、急诊及抢救区域的布局等进行改造、对医院各部门的工作流程、管理制度进行相应的调整以适应胸痛中心流程优化需求，承诺在分诊、就诊、检验、检查、收费、取药等环节实行急性胸痛优先原则，在急性胸痛患者就诊时首份心电图、肌钙蛋白等辅助检查、ACS的抗血小板药物、STEMI患者的抗凝、溶栓治疗环节等实行先救治后收费的原则，以适应优化诊疗流程、最大限度缩短救治时间的需要；<br>
                      承诺与院前急救系统及社区医院及乡镇卫生院签署联合救治协议，推动区域内胸痛救治单元的建设，以实现区域协同救治体系的建立；<br>
                      承诺支持并协助胸痛中心实施各类培训计划；<br>
                      若救护车归属医院管理，承诺对救护车救治能力进行改造，包括人员培训及设备更新，以满足转运急性胸痛患者的需求。
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">胸痛急救的配套功能区域设置及标识</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">急诊科、胸痛中心的标识与指引</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">1.15
                      在医院周边地区的主要交通要道、医院门诊、急诊的入口处设置醒目的胸痛中心或急诊的指引和标识，旨在为不熟悉医院环境的急性胸痛患者能顺利找到急诊科或胸痛中心
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.16 在门诊大厅、医院内流动人群集中的地方均应有指引通往急诊科/胸痛中心的醒目标识，指引需要急救的患者快速进入急诊科/胸痛中心</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.17 急诊科分诊、挂号、诊室、收费、抽血、检验、检查、药房等均应有急性胸痛优先标识。</div>
                    <div class="line"></div>
                  </div>
                  <div style="margin-top: 15px;">
                    <div>
                      <span class="text-title">胸痛急救的功能分区</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div style="width: 75%;">胸痛中心的大部分初步诊疗工作在急诊科完成，急诊科应建立如下功能区：</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.18
                      急诊分诊台应易于识别且靠近抢救区，方便步行患者进入时发现；所有进入急诊科就诊的患者均需经过分诊台分诊后才能就诊；对于急诊量较小、不具备设置急诊分诊条件的医院，必须建立替代机制以确保急性胸痛患者得到快速诊疗
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.19 急诊分诊台或功能替代区应配置电话及急救相关的联络系统，以便进行院内、外的沟通协调，其中应包括与院前救护车、向本院转诊的基层医院的联络机制
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.20
                      急诊分诊台应常备急性胸痛患者时间管理节点记录表，以及伴随时钟（如果需要），以便在首次医疗接触时开始进行前瞻性时间节点记录，或者能在分诊台开始启动填报胸痛中心云平台数据库
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.21 分诊区有标准的胸痛分诊流程图，指引分诊护士在初步评估后将患者分流到胸痛诊室、急诊抢救室、胸痛留观室或直接送入导管室</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.22 急诊科入口处应根据急诊流量配备足够的轮椅和担架车，方便多个患者同时就诊时使用</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.23
                      急诊科应具备床旁心电图检查条件，确保在首次医疗接触后10分钟内完成首份12/或18导联（怀疑下壁和正后壁心肌梗死）心电图检查，并不受是否为正班时间的限制，对于急性胸痛患者首份心电图应实行先救治后收费原则
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.24 急诊科应具备床旁快速检测肌钙蛋白及D-二聚体的设备，确保抽血后20分钟获取检测结果</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.25
                      应建立胸痛诊室（专用或兼用）、急诊抢救室（或急诊监护室）、胸痛留观室（供暂时诊断不明确、需要留观的中、低危胸痛患者使用）等功能区域，上述功能区应配备急性胸痛诊疗和抢救所需要的相应设施（例如心电图机、供氧系统、监护仪、除颤器、呼吸机等急救器材和急救药品），上述抢救设备、面积、床位等配置应以能满足医院所承担的急诊任务为原则
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">人员资质及专科救治条件</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">人员资质</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">1.26
                      至少有2名取得中级职称资格且从事心血管内科临床工作3年以上的心血管内科专业医师，专业资质的认定需满足以下两个条件：一是获得心血管内科专业硕士以上学位或在三级甲等医院进修心血管内科专业6个月以上，二是每年（认证时提交连续2年）参加ACS相关继续教育的证明
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.27
                      应具备开展心血管内科常见疾病专科诊疗的基本条件，设有开放床位不小于20张的心脏专科病房或心脏病患者专用床位；应配有不少于2张的心脏重症监护室（CCU、ICU或EICU）或心脏重症专用床位
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.28 每年接受或转诊的急性心肌梗死患者不少于30例</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.29
                      已建立为诊断明确的ACS患者在10分钟以内开始双联抗血小板和抗凝治疗的流程图，根据预计的再灌注策略使用指南推荐的双联抗血小板和抗凝药物剂量，首次负荷量的抗血小板和抗凝药物应实行先救治后收费原则
                    </div>
                    <div class="line"></div>
                  </div>

                  <div style="margin-top: 15px;">
                    <div>
                      <span class="text-title">本院胸痛中心所制订的STEMI再灌注治疗方案中包含有溶栓治疗，应具备以下基本条件：</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div style="width: 75%;">1.30
                      溶栓场所，为达到在首次医疗接触后30分钟内实施溶栓治疗的目标，溶栓场所最好是方便患者快速到达的急诊科抢救室或CCU，亦可在其它重症监护室，但均必须具备心电、血压、血氧饱和度等监护条件以及处理再灌注心律失常、心力衰竭、实行心肺复苏的相应条件，包括相应的抢救设备及人员配备
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.31
                      常备溶栓药物：最好备用特异性纤溶酶原激活剂，溶栓药物的保存地点、领用机制等应能体现先救治后收费的原则，为实现在首次医疗接触后30分钟内开始溶栓治疗创造条件
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.32
                      溶栓团队：应由急诊和心血管内科/CCU或ICU专业人员组成，能熟练掌握STEMI的诊断、溶栓适应症、禁忌症、溶栓药物使用方法、溶栓注意事项、溶栓结果判定标准、各种并发症的处理以及心肺复苏能力，如果值班一线医师不具备上述能力，要有相应的支援机制以确保全天候开展溶栓治疗，在满足进门-溶栓时间小于30分钟的基础上，逐步实现首次医疗接触后30分钟内开始溶栓治疗的目标
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.33
                      溶栓后治疗方案：若在本院实施补救性PCI治疗方案的，导管室基本条件和介入人员资质应能够满足要求；若溶栓后实施转运PCI，则必须依据就近原则与至少一家以上具备心血管救治能力（优先选择通过认证的胸痛中心）的上级医院建立双向转诊合作，具备全天候转运STEMI患者的救护车，包括车载设备和人员具备处理转运途中并发症的能力
                    </div>
                  </div>
                  <div style="margin-top: 15px;">
                    <div>
                      <span class="text-title">本院胸痛中心所制订的STEMI再灌注治疗方案中包含有在本院实施急诊PCI治疗，应具备以下基本条件：</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">1.34
                      导管室基本条件：具备能进行急诊冠状动脉介入诊疗的导管室基本设备（状态良好的数字血管影像设备、监护设备——含无创和有创性血流动力学监护设备、呼吸机、除颤器、心脏临时起搏器、主动脉内球囊反搏仪等生命支持系统）
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.35 介入人员资质：至少一名以上具备急诊PCI能力的介入医师，要求接受过规范的介入诊疗技术培训、年手术量不低于75例</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.36
                      至少具有2-3名经过专门介入辅助技术培训、熟悉导管室工作流程的导管室专职护士，且每年至少接受一次4学时以上的介入诊疗和ACS的新知识培训，并获得证书
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.37 具有经过专门培训且获得大型放射设备上岗证书的放射技术人员</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.38 常备急诊PCI相关的各类消耗性器材</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.39
                      若本院导管室及监护室均具备急诊PCI手术和监护条件，但急诊介入医师能力不足时，可实施转运介入医生方案，但应至少与2名以上的外院介入医生签订合作协议，要求介入医生具备介入治疗能力，且能够确保D-to-W时间在90分钟内
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">胸痛诊断及鉴别诊断的基本支持条件</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">是否满足</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">1.42 具备随时进行超声诊断的能力，包括心脏超声及主动脉超声</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.43 具备多排螺旋CT增强扫描的条件，并能开展急诊主动脉、肺动脉CTA检查，从启动CT室到接受患者进行检查的时间在30分钟以内</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.44 在对急性胸痛进行鉴别诊断时，能得到其它相关学科的支持，例如呼吸科、胸外科、消化科、皮肤科等</div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">时钟统一方案及管理</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">是否满足</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">1.45 已建立时钟统一方案，以确保各关键诊疗环节的时间节点记录的准确性</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.46 已制订了时钟统一管理制度，确保关键时间节点所涉及的各类时钟、诊疗设备内置系统时间、各类医疗文书记录时间的高度统一</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.47 能提供落实时钟统一管理制度的客观记录，如时钟校对记录等</div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">数据库的填报与管理</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">是否满足</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">1.48 已开始启用胸痛中心数据填报平台，并至少提供近6个月的数据供认证时评估</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.49
                      制定了数据库的管理规范、使用细则及监督管理制度，并有数据的审核制度，确保数据库的真实、客观、准确、及时；并能够与建立了转诊关系的上级胸痛中心医院实现数据共享
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.50 应有专职或兼职的数据管理员</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.51 对相关人员进行了数据库使用方法和相关制度的培训</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.52
                      急性胸痛患者的首次医疗接触的人员应及时在数据库中建档，若不能及时进行在线填报，应有纸质版的时间记录表格伴随患者诊疗的全过程，进行时间节点的前瞻性记录，尽可能避免回顾性记录，以提高记录的准确性
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">数据库的完整性，应满足以下全部条件：</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.53
                      所有急性胸痛患者均应从首次医疗接触开始启动时间节点记录。急诊分诊台应建立分诊登记制度，确保所有急诊就诊患者（包括但不限于急性胸痛患者）均能在同一入口登记，可以使用电子分诊系统或纸质记录本进行登记，并能对其中的急性胸痛病例进行检索或标记
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.54
                      所有进入医院（包括就诊于门诊、急诊或绕行急诊直接入院的患者）的高危急性胸痛（ACS、主动脉夹层、肺动脉栓塞及其他重要急性胸痛疾病，明确的创伤性胸痛除外）均应上报至胸痛中心数据填报平台
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.55 ACS患者的登记比例应达到100%</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.56 STEMI患者的录入必须达到100%，且各项关键时间节点的填报应齐全</div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.57
                      NSTEMI/UA患者院内、出院等关键时间节点的记录完整性应达到100%，初步诊断为NSTEMI/UA均需进行缺血风险评估，以便能够及时根据患者的评估情况进行合理救治
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.58
                      数据资料的溯源性：确保STEMI患者的上述关键时间节点可以溯源，其中发病时间、呼叫120、到达医院等时间应能从急诊病历（电子病历或复印件）、入院病历、首次病程记录、心电图纸、检验报告、病情告知或知情同意书等原始记录中溯源，并要求尽可能精确到分钟
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">胸痛中心协同救治信息化建设</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">胸痛中心信息化建设是未来胸痛中心高质量运行的基础，也是胸痛中心质控工作开展的重要支撑，建设行之有效、功能完备的胸痛中心协同救治信息化系统平台，对于降低数据采集难度、减轻数据填报人员的工作负荷以及规范数据填报质量都有重要意义，也是胸痛中心可持续发展的重要保障</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">1.59
                      建立了包含远程实时传输心电图为基础功能的包括胸痛中心信息系统、微信群、手机短信等形式的信息共享平台或专业的胸痛中心协同救治信息系统，以支持具有确诊能力的上级医师能及时为急诊一线提供全天候支持，确保心血管内科医师能在10分钟内参与会诊、协助诊断
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.60
                      上述信息共享平台或专业的胸痛中心协同救治信息系统至少要与周边5家以上的非PCI网络医院或胸痛救治单元实现信息共享并签署联合救治协议，以便及时为非PCI医院的急性胸痛患者提供诊断支持，同时为实施转运PCI的STEMI患者绕行急诊科和CCU直达导管室提供条件
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">1.61 有条件的医院尽可能采用时间节点及诊疗信息自动获取的信息管理系统，以提高数据管理的自动化水平和可靠性</div>
                  </div>
                </div>

              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="要素二 对急性胸痛患者的评估及救治" name="2">
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">急性胸痛患者的早期快速甄别</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">此部分的重点是在急性胸痛患者就诊后早期进行病因的初步判断以及对生命体征不稳定的高危胸痛患者的识别，必须满足以下全部条件。</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                         <el-radio v-model="radio" label="2">不满足</el-radio>
                       </span>
                    </div>
                    <div style="width: 75%;">2.10
                      制订了急性胸痛分诊流程图，该流程图必须包括详细的分诊细节，指引分诊护士或承担类似分诊任务的首次医疗接触医护人员在进行分诊和初步评估时将生命体征不稳定的患者快速识别出来并尽快送进急诊抢救室，生命体征稳定的急性胸痛患者尽快完成首份心电图并由接诊医师进行初步评估
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.11 所有负责分诊的人员及其他首次接诊急性胸痛患者的医护人员均熟悉上述分诊流程图</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.12
                      制订了急性胸痛鉴别诊断流程图，指引首诊医师对胸痛的原因做出快速甄别，该流程图中必须包括ACS、急性主动脉夹层、肺动脉栓塞、急性心包炎、气胸等以急性胸痛为主要表现的常见疾病，流程图应能指引一线医师选择最有价值且本院具备的辅助检查方法以快速完成上述疾病的诊断和鉴别诊断
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.13 所有负责急性胸痛患者接诊的急诊医师熟悉上述诊疗流程图</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.14 所有急性胸痛患者在首次医疗接触后10分钟内完成12/18导联心电图检查</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.15
                      确保在首份心电图完成后10分钟内由具备诊断能力的医师解读，若急诊医师不具备心电图诊断能力，心血管内科医师或心电图专职人员应在10分钟内到达现场进行确认，或通过远程12导联心电图监护系统或微信传输等方式远程确认心电图诊断
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.16 所有急性高危胸痛患者应在首次医疗接触（分诊台或挂号）后10分钟内由首诊医师接诊</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.17 急诊科护士或医师或其它急诊检验人员熟练掌握了床旁快速检测肌钙蛋白的方法，确保能在从抽血结束到获得检测结果不超过20分钟</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.18 制订了ACS诊治总流程图，当心电图提示为ACS时，该流程图能指引一线医师进行后续的诊疗过程</div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">对明确诊断为STEMI患者的再灌注流程</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">2.19 以最新的STEMI诊治指南为依据，结合本院实际情况制订STEMI再灌注治疗策略，该流程图应包括了各种不同来院途径的STEMI患者；具备PPCI能力的医院（但当前无法达到PCI医院胸痛中心认证标准者）应以PPCI为首选治疗策略；对于不具备PPCI能力的医院，应根据是否能在120分钟内完成转运PCI确定本院STEMI优先选择的再灌注策略及不能实施首选策略时的次选策略，并明确首选及次选策略的选择条件，以指引一线医师选择</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">2.20 根据最快到达的原则与附近至少一家以上已经建立胸痛中心的PPCI医院（优选通过标准版胸痛中心认证的单位）建立转诊关系，并需签署联合救治协议（加盖医院公章），原则上应建立双向转诊机制，该协议应明确双方的责任与义务，以便及时转运本院无法救治的危重心血管等疾病，包括STEMI患者；若与两家以上接受转诊医院建立了转诊关系，应根据转运时间优先并结合导管室是否可用确定优选和次选转诊的医院，并制订流程图指导一线医护人员使用</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">若再灌注策略中包括了溶栓治疗，则必须满足以下全部条件：</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div style="width: 75%;">2.21 有规范的溶栓筛查表，其中包括STEMI的确诊条件、溶栓适应症、禁忌症；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.22 有规范、制式的溶栓治疗知情同意书，医患双方签字时间应精确到分钟；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.23 制订了溶栓治疗方案，包括溶栓前准备、溶栓药物选择及剂量、用法、监测指标及时机、结果判断、并发症处理预案、溶栓后抗凝治疗方案等；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.24 制订了溶栓治疗标准操作流程图，指引一线医师进行溶栓治疗；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.25 建立流程优化机制，确保从自行来院或经120入院的STEMI患者能在首次医疗接触后30分钟内开始溶栓治疗(FMC-to-N)；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.26 制订了溶栓后转运方案和转运机制，其中包括转运时机、与PCI医院的联络机制、转运流程、转运途中病情变化时的应急预案等安全保障措施。</div>
                    <div>
                      <span style="width:75%;float: left;"
                            class="text-title">若再灌注策略中包括了在本院实施PPCI或转运介入医生者，则应满足以下全部条件：</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div style="width: 75%;">2.31 制订了明确的PPCI治疗的适应症和禁忌症；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.32 制订了STEMI患者PPCI治疗流程图，确保D-to-W时间≤90分钟，该流程图中应包括：</div>
                    <div class="line"></div>
                    <div style="width: 75%;">经救护车入院的STEMI患者应绕行急诊和CCU直达导管室</div>
                    <div class="line"></div>
                    <div style="width: 75%;">自行来院STEMI患者绕行CCU从急诊科直达导管室</div>
                    <div class="line"></div>
                    <div style="width: 75%;">先救治后收费机制</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.33 建立了旨在缩短知情同意时间的有效方法；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.34 为救护车及急诊科提供了PPCI治疗的一键启动机制；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.35 建立了导管室激活机制，确保在启动后30分钟内接纳STEMI患者；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.36 若本院医生不具备PPCI能力，需要从外院转运介入医生，应制定标准的联络和转运流程图及方案，确保D-to-W时间<=90分钟。</div>
                    <div class="line"></div>
                    <div>
                      <div style="width:75%;float: left;" class="text-title">2.37
                        制订了相应的流程，使从网络医院或胸痛救治单元首诊，转运至本院或绕行本院直接转送上级医院的STEMI患者能在到达医院前确认诊断、启动救治流程直达救治场所，并至少与5家网络医院或胸痛救治单元实施了上述流程；
                      </div>
                      <div style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </div>
                    </div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">2.38 制订了本院STEMI患者的药物治疗方案，包括发病后早期用药及长期二级预防方案。</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">对初步诊断为NSTEMI/UA患者的危险分层及治疗</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">由于NSTEMI/UA患者的病情严重程度差异很大，需要根据危险程度分层施治，因此，胸痛中心应根据专业指南要求建立基于危险分层的治疗策略。以下条件必须全部满足：</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">2.39 制订了对NSTEMI/UA患者进行初步评估及再次评估的流程图，其中必须明确评估内容、危险分层工具及再次评估时间；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">(1)NSTEMI/UA初始评估和再次评估流程图必须符合当前指南精神；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">(2)程图应有首次、再次评估的具体内容；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">(3)应有公认的危险分层工具，包括缺血和出血评分工具；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">(4)流程图中应明确根据情况确定心电图和肌钙蛋白复查的时间和再次评估的间隔时间，以便根据临床情况的变化调整相应的再灌注治疗策略，必须满足以下三项：
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">
                      ①初始心电图和/或持续ST段监护结果为阴性时，按规定的时间定期复查心电图，确保症状复发或恶化时，应在15-30分钟的间隔内重新采集心电图；无持续或复发性症状且临床情况稳定的患者应在不超过4小时内复查心电图；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">
                      ②确定心肌生化标志物诊断NSTEMI的标准界値，生化标志物中必须包含肌钙蛋白，有条件时可开展超敏肌钙蛋白检测，以满足快速评估和早期诊断的需要，应确保能在抽血后20分钟获得肌钙蛋白检测结果；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">③若首次肌钙蛋白为阴性，则应在入院后6小时内复查，若采用高敏肌钙蛋白，则应根据当前指南确定复查时间；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.40
                      制订相应的流程，确保首次或再次评估为极高危的患者能在2小时内实施紧急PCI治疗；若不能在本院实施紧急PCI，则应与接受转诊的PCI医院合作，建立联络及转诊机制，包括转运救护车的派遣、转运途中病情变化时应急预案以及达到接受医院的目标科室；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.41 强调一旦NSTEMI或UA转变为STEMI，应立即按STEMI流程执行后续治疗；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.42 上述评估过程和临床实际工作中应尽可能避免医疗资源的浪费，防止过度检查和治疗；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.43 依据指南制订了NSTEMI/UA患者的药物治疗规范，包括早期药物治疗及长期二级预防方案；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.44
                      建立规范的流程，使首次或再次评估为高危或中危的患者能在指南规定的时间内接受早期或延迟介入治疗；若不能在本院实施PCI，则应与接受转诊医院建立联络及转运机制，明确转运时机；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.45
                      对于初步和再次评估均为低危的ACS患者，若医院具备条件，应安排患者进行心脏负荷试验，不具备条件时也可行冠状动脉CTA评估，并根据结果决定是否接受冠状动脉造影检查，对于不具备条件的医院应安排择期转院评估；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.46 与接受转诊医院共同制订了ACS患者在完成PCI治疗后病情稳定情况下即时转回本院进行后续康复治疗和长期随访的方案。</div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">对低危胸痛患者的评估及处理</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">对于基本排除急性心肌梗死、主动脉夹层、肺动脉栓塞、气胸、急性心包炎等中高危急性胸痛、且诊断不明确的患者，应归入低危胸痛范畴，应对此类患者给出具体的评估方法，确保既不浪费医疗资源又不漏诊。可采用的方法包括：急诊短期留观、重复心电图检查、心脏生化标志物、心脏负荷试验、影像学检查等。对于明确排除了ACS的低危胸痛患者，离院时应告知随访时机。  不满足  满足</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">2.47
                      在胸痛鉴别诊断的流程图中应尽可能全面考虑其他非心源性疾病；对于症状提示为非心源性胸痛，流程图应能指引一线医师进行相关的辅助检查以进一步明确诊断，同时应尽量避免医疗资源的浪费；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.48
                      对于症状提示ACS但初始评估诊断不明确、暂时无急性心肌缺血证据的急性胸痛患者，应制订根据不同临床症状复查心电图、肌钙蛋白的时间间隔，确保病情变化或加重时能被及时评估，又避免医疗资源的浪费
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.49
                      对于具备心电图运动试验条件的医院，低危胸痛的评估流程中应包含心电图运动试验，并应制订运动心电图的适应症、禁忌症、标准操作规程、结果判断标准、并发症的处理措施；对于不具备运动心电图条件的医院，应对后续的评估给出明确的建议，包括可能的替代性评估方法或建议患者转到上级医院做进一步评估；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.50 对于完成基本评估从急诊直接出院的低危胸痛患者，医师应根据病情制订后续诊疗和随访计划，并进行冠心病的知识宣传教育；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.51
                      对于未完成全部评估流程而提前离院的急性胸痛患者，急诊医师应告知潜在的风险、再次症状复发时的紧急处理、预防措施等注意事项，签署并保存相关的医疗文书及知情文件。
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">院内发生ACS的救治</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">院内发生的ACS包括因非心血管病住院期间新发生的ACS及因误诊收入其它科室的ACS，针对此类患者，胸痛中心应满足以下全部条件：</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">2.52
                      制订院内发生ACS时的救治流程图，该流程图应包括从明确诊断到实施关键救治的全部过程，明确患者所在科室的现场处理要点、会诊机制及紧急求助电话；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.53 全院各科室人员均应熟悉ACS现场救治的基本流程和会诊机制，熟练掌握心肺复苏的基本技能，熟悉紧急联系电话。</div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">对急性主动脉夹层及急性肺动脉栓塞的诊断及处理</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">是否满足</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">2.54
                      经临床初步评估高度怀疑主动脉夹层或急性肺动脉栓塞的患者，能在30分钟内进行“增强CT扫描”，不具备CT增强扫描条件者应在病情允许时尽快转移至具有诊治条件的医院明确诊断；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.55 怀疑A型夹层、急性心包炎者能在60分钟内完成心脏超声检查；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.56
                      制订了主动脉夹层的早期紧急治疗方案，若无禁忌症，在明确诊断后能尽快实施以β-受体阻滞剂和静脉药物为主的降压和镇痛治疗方案，以降低主动脉夹层破裂的风险，为后续治疗赢得时间
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.57
                      明确诊断或高度怀疑为急性主动脉夹层的患者，若本院不具备急诊介入治疗及外科手术条件，应与具备诊疗能力的医院建立转诊关系，并制定明确的转诊适应症和转运途中病情变化时的应急措施，以尽快将不稳定的患者及时转运至具备救治能力的医院接受最佳治疗；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.58 制订了急性肺动脉栓塞的诊断筛查流程图；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.59
                      制订了急性肺动脉栓塞的标准治疗方案，对于诊断明确的患者能根据危险分层及时开始相应的治疗措施；对于具备溶栓适应症的患者能在诊断明确后及时开始溶栓治疗；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.60 对于高危肺动脉栓塞患者，若本院不具备条件，应与具备救治能力的医院建立转诊关系，能在诊断明确后及时转诊</div>
                    <div class="line"></div>
                    <div style="width: 75%;">2.61 对于高危肺动脉栓塞患者，若本院不具备条件，应与具备救治能力的医院建立转诊关系，能在诊断明确后及时转诊</div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">建立了ACS患者随访制度</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">2.62 建立了ACS患者随访制度，以便对出院后ACS患者进行长期的管理，提高患者康复质量，降低风险，原则上所有ACS患者均应建立随访档案，并在数据填报平台应及时填报；</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </el-collapse-item>
          <el-collapse-item title="要素三 基本条件与资质" name="3">
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">胸痛中心应与120建立紧密合作机制，必须满足以下内容：</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">3.10 医院应围绕急性胸痛救治与本地区120签署正式的合作协议，共同为提高急性胸痛患者的救治效率提供服务。该协议必须包括针对急性胸痛患者的联合救治计划、培训机制、共同制定改进质量的机制；申请认证时应提交双方盖章的正式协议，此协议必须在正式申请认证之前至少6个月签署生效。</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">3.11 胸痛中心制订了针对急性胸痛的急救常识、高危患者的识别、ACS及心肺复苏指南等对120相关人员进行培训的计划，并有实施记录；申请认证时应提交：</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">胸痛中心与120共同制订从胸痛呼救到从发病现场将急性胸痛患者转送至胸痛中心的急救预案、流程图以及联络机制，并进行联合演练；申请认证时应提交：</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div style="width: 75%;">3.12 演练方案</div>
                    <div class="line"></div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">3.14 院前急救人员参与胸痛中心的联合例会和典型病例讨论会，至少每半年参加一次上述会议，共同分析实际工作中存在的问题、制订改进措施；</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">3.15 行转运PCI的患者应采用救护车转运，并尽最大可能进行单程转运。转运急性胸痛患者的院前救护车应具备基本的监护和抢救条件，必备设备包括心电图机、多功能（心电、血压、血氧饱和度等）监护仪、便携式除颤器、移动式供氧装置、人工气道建立设备和各类急救药品等，有条件时尽可能配备便携式呼吸机、吸引器、具有远程实时传输功能的监护设备、心脏临时起搏器、心肺复苏机。救护车随车医护人员应熟悉高危胸痛的紧急处理流程，并定期参加本院的相关培训和两会。</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                  </div>

                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">胸痛中心与120的合作提高了急性胸痛的院前救治能力，至少满足以下8项，其中2-8项为必备条件</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">是否满足以下条件：</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div style="width: 75%;">3.16
                      本地120急救系统管理人员及调度人员熟悉区域协同救治的理念，理解“根据救治能力优先”的含义，并能在力所能及的范围内合理统筹调配本地院前急救医疗资源。
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">3.17 120调度人员能够熟练掌握胸痛急救常识，能优先调度急性胸痛救护并指导呼救者进行正确的现场自救；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">3.18 从接受120指令到出车时间不超过3分钟；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">3.19 院前急救人员能在首次医疗接触后10分钟内完成12导联（怀疑右室、后壁心肌梗死患者18导联）心电图记录；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">3.20 院前急救人员能识别ST段抬高心肌梗死的典型心电图表现；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">3.21
                      院前急救人员熟悉胸痛中心院内绿色通道的联络机制，能在完成首份心电图后10分钟内将心电图传输到胸痛中心信息共享平台（远程实施传输系统或微信平台），并通知具有决策能力的医生；对于从首次医疗接触到进入医院大门时间大于15分钟的急性胸痛患者，传输院前心电图的比例不低于50%；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">3.22 院前急救人员熟练掌握了高危急性胸痛患者的识别要点；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">3.23 院前急救人员熟练掌握了初级心肺复苏技能；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">3.24 对于急性胸痛的救治，120与胸痛中心采用相同的时间节点定义，院前急救人员熟悉各个时间节点定义；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">3.25 对于急性胸痛患者，实现了从救护车首次医疗接触时开始记录时间管理表或开始填报数据库云平台；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">3.26 对于首份心电图诊断为STEMI的患者，应满足以下三条之一：</div>
                    <div class="line"></div>
                    <div style="width: 75%;">(1)以溶栓为主要再灌注策略者，院前急救系统能将患者直接送到进行溶栓治疗的地点；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">(2)对于以在本院实施PPCI治疗为主要再灌注策略者，院前急救系统应能实施绕行急诊将STEMI患者直接送进导管室；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">
                      (3)对于距离上级PPCI医院较近，以转运PCI为主要再灌注策略，并由120负责实施转运任务的地区，120能通过共享的信息平台的指引将患者直接转运至PPCI医院直达导管室（绕行:非PPCI医院、上级医院急诊科、CCU。实施“三绕行”）。
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="要素四 培训与教育" name="4">
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">培训和教育</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">胸痛中心所在医院的全院培训，又分为以下几个不同的层次</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div style="width: 75%;">
                      针对医院领导、医疗管理、行政管理人员的培训，应在本院胸痛中心成立之前或最晚成立之后1个月以内至少进行一次。培训内容应包括：区域协同救治体系胸痛中心的基本概念、在胸痛中心建设和流程优化过程中需要医院解决的主要问题等。
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.10
                      申请认证时提交：培训计划及实际完成情况（包括预计培训时间，授课人，参加培训对象，培训时长，会议实际召开时间），每次培训会议签到表，讲稿，培训记录和现场照片（能显示授课时间、包括授课人及第一张幻灯片在内的照片以及包括听众在内的授课场景的照片至少各1张）；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">
                      针对急诊科、心血管内科、ICU等直接参与急性心肌梗死等急性胸痛救治工作的各专科医师和护士的培训计划，在正式成立胸痛中心后1个月内完成全面培训，以后每年进行一轮以确保新增人员得到及时培训。培训内容包括：<br>
                      (1)基于区域协同救治体系胸痛中心的基本概念；<br>
                      (2)胸痛中心的时钟统一、时间节点的定义及时间节点管理要求；<br>
                      (3)胸痛中心各项管理制度；<br>
                      (4)ACS发病机制、临床表现、最新的STEMI、NSTEMI/UA诊治指南；急性主动脉夹层、肺动脉栓塞的诊断及治疗指南；<br>
                      (5)本院胸痛中心的救治流程图，其中分诊流程、急性胸痛的诊断与鉴别诊断流程、STEMI从首次医疗接触至球囊扩张/溶栓、NSTEMI/UA的危险分层及治疗流程图是重点；<br>
                      (6)若本院的再灌注流程图中包括了溶栓治疗，则培训计划中必须包括溶栓治疗的标准操作规程（筛查表、溶栓流程图、结果判断、并发症处理）及转运至PCI医院的联络机制；<br>
                      (7)急性心肌梗死、常见心律失常的心电图诊断；<br>
                      (8)心肺复苏技能，此项培训应包括讲课、演示及模拟操作；<br>
                      (9)胸痛诊疗过程中的数据采集及胸痛中心认证云平台数据库填报；<br>
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.11
                      申请认证时提交：培训计划及实际完成情况（包括预计培训时间，授课人，参加培训对象，培训时长，会议实际召开时间），每次培训会议签到表，讲稿，培训记录和现场照片（能显示授课时间、包括授课人及第一张幻灯片在内的照片以及包括听众在内的授课场景的照片至少各1张）；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">
                      针对医院领导、医疗管理、行政管理人员的培训，应在本院胸痛中心成立之前或最晚成立之后1个月以内至少进行一次。培训内容应包括：区域协同救治体系胸痛中心的基本概念、在胸痛中心建设和流程优化过程中需要医院解决的主要问题等。
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.12
                      申请认证时提交：培训计划及实际完成情况（包括预计培训时间，授课人，参加培训对象，培训时长，会议实际召开时间），每次培训会议签到表，讲稿，培训记录和现场照片（能显示授课时间、包括授课人及第一张幻灯片在内的照片以及包括听众在内的授课场景的照片至少各1张）；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">
                      针对医疗辅助人员和后勤管理人员的培训计划，在成立胸痛中心后1个月内完成培训，以后每年进行一轮以确保新增人员得到及时培训，培训内容包括：胸痛中心的基本概念、院内紧急呼救电话、心脏按压的基本要领等；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.13
                      申请认证时提交：培训计划及实际完成情况（包括预计培训时间，授课人，参加培训对象，培训时长，会议实际召开时间），每次培训会议签到表，讲稿，培训记录和现场照片（能显示授课时间、包括授课人及第一张幻灯片在内的照片以及包括听众在内的授课场景的照片至少各1张）；
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">全员培训效果检验</div>
                    <div class="line"></div>
                    <div style="width: 75%;">现场核查时专家进行岗位检验及随机访谈</div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.14 急诊及心血管专业人员访谈</div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.15 非急诊及心血管专业的医护人员</div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.16 医疗辅助人员</div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">对本地区其它基层医疗机构的培训</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">对本地区其它基层医疗机构的培训是胸痛中心的重要职责之一，扩大胸痛中心救治覆盖范围，积极推动本地区胸痛救治单元的建设和管理，申请认证时必须满足以下全部条件：</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div style="width: 75%;">
                      已制定针对其它基层医疗机构的培训计划，该计划必须包括以下内容：基于区域协同救治体系胸痛中心的基本概念、急性胸痛快速转诊机制及联络方式、高危急性胸痛及ACS早期症状识别、急性心肌梗死和常见心律失常的心电图诊断、初级心肺复苏技能，应在成立胸痛中心后2个月内完成上述全部培训计划，以后每年进行一轮。申请时应提交：
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.17 培训计划：包括预计授课时间、内容、授课人、课时等；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.18 讲稿</div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.19
                      已经在至少5家以上的本地区其它基层医疗机构实施上述培训计划，申请认证时应提交实施上述培训计划的客观依据，包括但不限于：培训记录、签到表、能显示时间和内容的培训现场照片。
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">①培训记录合辑；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">②签到表合辑；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">③能显示授课时间、包括授课人及第一张幻灯片在内的照片以及包括听众在内的授课场景的照片或视频资料合辑；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.20 基层医疗机构熟悉区域协同救治体系的概念及与胸痛中心的联络机制。</div>
                  </div>
                </div>

              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">社区教育</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">社区人群教育是指胸痛中心积极参与对社区人群进行有关早期心脏病发作的症状和体征的识别以及紧急自救的培训，这是胸痛中心的重要职责之一，胸痛中心必须承担公众健康教育义务并积极致力于通过对公众教育来降低心脏病发作及死亡率，提高公众对急性胸痛危险性的认识以及在胸痛发作时呼叫120的比例，这是缩短从发病到就诊时间的最有效手段。</span>
                      <span style="float: right;">
                    <el-radio v-model="radio" label="1">满足</el-radio>
                    <el-radio v-model="radio" label="2">不满足</el-radio>
                  </span>
                    </div>
                    <div style="width: 75%;">4.21
                      为社区人群提供ACS症状和体征以及心脏病早期诊断的培训计划，至少包括下列项目中的五项，且要求每年至少进行一次。申请时需提交培训计划和讲稿：
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">⑴通过定期举办讲座或健康咨询活动，为社区人群提供有关心脏病症状、体征、早期诊断以及急救处理方法的培训；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">⑵向社区发放有关心脏病症状和体征以及早期诊断的科普性书面材料；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">⑶胸痛中心向社区提供健康体检、义诊等心血管健康筛查服务；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">⑷通过各类媒体、网络、社区宣传栏等途径提供心脏病和急救常识的教育；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">⑸向社区提供饮食健康及营养课程、戒烟、运动指导等健康生活的培训指导；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">⑹向公众宣传拨打120急救电话的重要性；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">⑺对社区人群进行心肺复苏技能的基本培训和教育。</div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.22
                      已经在医院周边地区至少两个以上社区实施了上述培训计划，申请认证时应提交实施上述培训计划的客观依据，包括但不限于：培训记录、能显示时间和内容的培训现场照片或视频资料。
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">4.23
                      缩短患者救治时间，应当重视院前急救，特别是患者自救等，胸痛中心应当积极进行大众教育，组织和开展大众心肺复苏培训。组建心肺复苏培训团队，建立规范的培训制度，有规范的培训教材，有统计的登记及考核管理。至少每季度举行一次心肺复苏培训和教育，每次培训参加人员不低于20人。应根据本区域社区分布情况，制定相应的培训计划，逐步覆盖本地区社区。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item title="要素五 持续改进" name="5">
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">医院应制订促进流程改进和质量改进的计划和措施</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">5.10 胸痛中心应根据当前的实际情况确定本中心关键监控指标及质量改进计划，例如：首次医疗接触至完成首份心电图时间、首份心电图至首份心电图确诊时间、首次医疗接触-溶栓时间、入门-溶栓时间、入门-出门（Door-in and door-out）时间、入门-导丝通过（D-to-W）时间、ACS院内死亡率等，并确立关键性效率指标和预后指标的近期奋斗目标值，原则上应每年修改一次奋斗目标值以体现持续改进的效果；申请认证时应提交所确立的监控指标及奋斗目标值。</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">5.11 关键流程图的改进记录，至少提交三个以上改进前后的关键流程图及改进说明；</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">制订了促进胸痛中心质量改进的重要管理制度并付诸实施，主要包括：</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div style="width: 75%;">5.12 联合例会制度：是胸痛中心为协调院内外各相关部门的立场和观念、共同促进胸痛中心建设和发展而设立的专门会议，要求在提交认证材料和现场核查时均要有胸痛中心与120以及其它具有转诊关系单位的联合例会制度以及实施记录，该制度应为联合例会制订规则，包括：主持及参加人员、频次、时间、会议讨论的主要内容等，原则上联合例会的时间间隔不得超过6个月；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.13 质量分析会制度：质量分析会的主要内容是通过对胸痛中心运行过程中的阶段性宏观数据分析，肯定工作成绩、发现存在问题并制订改进措施。除了胸痛中心的核心科室人员参加外，医院管理层及院前急救人员亦应参加。该制度必须为质量分析会制定出标准的规则，包括主持及参加人员、频次、时间、参加人员、主要分析内容等，原则上质量分析会的时间间隔不得超过3个月；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.14 典型病例讨论会制度：典型病例讨论会是改进胸痛中心工作质量最有效的工作形式之一，可与质量分析会同时举行，但主要是针对急诊科、心血管内科等胸痛中心的实际工作人员。一般是从质量分析会中发现宏观问题，再将存在救治延误或决策错误的典型病例挑选出来作为剖析的对象，将所有与执行流程相关的人员集中进行讨论和分析。典型病例讨论会制度就是为病例讨论会制定规则，主要内容包括会议主持人、参与讨论的人员范围、举行会议的频次、时间、会议流程等，原则上典型病例讨论会的时间间隔不得超过3个月；</div>
                    <div class="line"></div>
                    <div>
                      <span style="width:75%;float: left;" class="text-title">5.15 其它制度：如与质量分析会制度配套的奖惩制度、各类人员值班制度等。</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="certificate-collapse-li tbc-lr-scatter">
              <div style="border:1px solid #DDDFE6;width: 100%;">
                <el-divider content-position="left">持续改进效果</el-divider>
                <div style="padding: 0 15px;margin-top: 40px">
                  <div>
                    <div>
                      <span class="text-title">胸痛中心在提交认证申请前应进行云平台数据库的自我检查及评估，当云平台数据库显示的数据趋势达到以下要求时方可正式提交认证申请。</span>
                      <span style="float: right;">
                        <el-radio v-model="radio" label="1">满足</el-radio>
                        <el-radio v-model="radio" label="2">不满足</el-radio>
                      </span>
                    </div>
                    <div style="width: 75%;">
                      胸痛中心通过流程改进已改善ACS患者救治的效率指标和预后指标，其中1-5项是必须满足的条件，6-8项中对应本院首选和次选再灌注策略的也为必须满足的条件，9-16项至少满足其中3项条件
                    </div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.16 对于自行来院或经救护车入院的所有急性胸痛患者，缩短了从首次医疗接触到完成首份心电图时间，且要求月平均小于10分钟；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.17 对于STEMI患者，缩短了从完成首份心电图至首份心电图确诊时间，且要求月平均小于10分钟；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.18 经救护车入院的STEMI患者，院前远程传输心电图至胸痛中心的比例不低于30%且在过去6个月内呈现增加趋势；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.19 肌钙蛋白从抽血完成到获取报告时间20分钟；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.20 发病至首次医疗接触在12小时以内的STEMI患者早期再灌注治疗（溶栓+PPCI）的比例不低于75%；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">对于实施溶栓再灌注策略的STEMI患者，应满足以下至少4条，其中第(1)和(3)条为必备条件：</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.21 适合溶栓的患者接受溶栓治疗的比例不低于50%且在过去6个月内呈现增加趋势；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.22 经120入院的STEMI患者直达溶栓场所的比例大于50%或呈明显增加趋势；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.23 所有院内溶栓STEMI患者进门-溶栓时间已明显缩短，平均时间应在30分钟以内，且至少75%的病例能达到此标准；如果目前无法达到上述要求，至少近6个月已经呈现出明显的缩短趋势且至少50%的病例达30分钟以内，且已制定合理计划以确保在通过认证后的第1年内达到平均30分钟以内且75%的合格率；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.24 所有院前溶栓患者，首次医疗接触-溶栓时间呈现缩短趋势，且小于30分钟的比例大于30%；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.25 溶栓后患者早期（2小时内）转运至上级医院的比例不低于50%且呈现增加趋势；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.26 溶栓（包含在网络医院，120及本院溶栓）后患者24小时内早期造影的比例不低于50%或呈明显增加趋势；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">对于实施直接转运PCI的STEMI患者，应满足以下全部条件：</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.27 在除外合并心源性休克、急性左心衰等需要PCI医院派出救护车双程转运的患者之后，月平均入门-出门(door-in and door-out）的时间应不大于30分钟，如果目前达不到，应显示明显的缩短趋势，并且需要针对当前存在的主要问题制订改进措施，确保在通过认证后1年内逐步达到；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.28 在过去6个月内实施转运PCI的患者中，向接收转诊的PCI医院传输心电图的比例不低于50%且呈现增长趋势；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.29 在过去6个月内实施转运PCI的患者中绕行PCI医院急诊科和CCU直达导管室的比例不低于50%；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.30 在过去6个月内实施转运PCI的STEMI患者，首次医疗接触到导丝通过时间应在120分钟以内。若单月转运病例少于5例，则应至少75%的病例能达到此标准。同时转运的上级医院实际介入手术开始时间及导丝通过时间反馈率为100%。</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.35 对于从基层网络医院转诊来院的全部STEMI患者，至少满足以下2条，不足2条者不得分：</div>
                    <div class="line"></div>
                    <div style="width: 75%;">(1)过去6个月内月平均首次医疗接触到完成首份心电图时间小于10分钟或呈明显缩短趋势；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">(2)过去6个月从首诊基层医院传输到胸痛中心的心电图比例在增加；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">(3)过去6个月内STEMI患者从首次医疗接触到确诊的时间在缩短；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">(4)对于网络医院实施转运的STEMI患者，网络医院的door-in and door-out(入门到出门）的时间在30分钟以内，若当前达不到，应有缩短趋势；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">(5)从网络医院或120转诊的STEMI患者绕行本院转运至上级医院直达导管室的比例在增加；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.36 STEMI入院患者中呼叫120的比例在增加；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.37 所有ACS患者从确诊到负荷量双抗给药时间在10分钟以内，有缩短趋势；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.38 所有STEMI患者（除使用第一代溶栓药者外）从确诊到静脉肝素抗凝给药时间有缩短趋势；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.39 初步诊断为NSETMI/UA的患者实施危险分层评估的比例达到100%；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.40 所有危险分层评估极高危的NSTEMI/UA患者，从入门后（首次评估为极高危者）或者病情变化后（再次评估为极高危者）2小时内在本院或转至上级医院实施紧急PCI的比例在增加，且不低于30%；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.41 所有危险分层评估高危NSTEMI/UA患者，从入门后（首次评估为高危者）或者病情变化后（再次评估为高危者）24小时内在本院或转至上级医院实施早期介入治疗的比例在增加，且不低于30%；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.42 全部ACS患者院内心力衰竭发生率在降低；</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.43 全部ACS患者院内死亡率在降低。</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.44 STEMI患者发病后2小时内就诊的比例在增加。</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.45 主动脉或肺动脉CTA完成时间有缩短趋势（怀疑主动脉夹层或肺动脉栓塞的患者，计算从通知CT室到CT室完成准备的时间，要求小于30分钟）。</div>
                    <div class="line"></div>
                    <div style="width: 75%;">5.46 ACS患者出院后1个月、3个月、6个月、1年的随访率均不低于50%。</div>
                  </div>
                </div>

              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div class="center-float-center mt-20">
          <el-button type="primary" @click="saveForm" size="large" :loading="loading">保存数据</el-button>
          <el-button type="primary" @click="submitForm" size="large" :loading="loading">正式发起认证申请</el-button>
        </div>
      </el-form>

    </div>

  </div>
</template>

<script>
import {getOrganDetail, postCertifiedUpload} from "@/api";
const getNowDate = () => {
  var date = new Date();
  var sign2 = ":";
  var year = date.getFullYear() // 年
  var month = date.getMonth() + 1; // 月
  var day = date.getDate(); // 日
  var hour = date.getHours(); // 时
  var minutes = date.getMinutes(); // 分
  var seconds = date.getSeconds() //秒
  // 给一位数的数据前面加 “0”
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (day >= 0 && day <= 9) {
    day = "0" + day;
  }
  if (hour >= 0 && hour <= 9) {
    hour = "0" + hour;
  }
  if (minutes >= 0 && minutes <= 9) {
    minutes = "0" + minutes;
  }
  if (seconds >= 0 && seconds <= 9) {
    seconds = "0" + seconds;
  }
  return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
}
export default {
  name: "MaterialIndex",
  data() {
    return {
      basicUrl: this.$basicUrl,
      loading:false,
      visible:true,
      type:'',
      isTypeDisplay:'',
      collapse: '',
      radio:'',
      organ:{},
      fileListNew:[],
      fileList: [],
      attestation:{
        fistMeasure:'',
        measure:[],
      },
      ruleForm: {
        certificates: []
      },
      upload201: {
        path: ''
      },
      upload202: {
        path: ''
      },
    }
  },
  mounted() {
    const self=this;
    if (JSON.parse(localStorage.getItem('cpis-web_userInfo'))) {
      self.organId = JSON.parse(localStorage.getItem('cpis-web_userInfo')).id
      self.reqOrganDetail()
    }
  },
  methods: {
    // 获取机构信息
    async reqOrganDetail() {
      const self=this;
      const res = await getOrganDetail(self.organId)
      self.organ = res.biz
      self.attestation=res.biz.attestation;
      if(self.attestation.qualityType =='BASIS'){
        self.type='基层版';
        self.isTypeDisplay=true;
      }else if(self.attestation.qualityType =='STANDARD'){
        self.type='标准版';
        self.isTypeDisplay=false;
      }
      self.fileListNew = (res.biz.attestation.files || []).map(item => {
        item = {...item, ...item.file}
        return item
      })
      self.fileList = self.fileListNew.filter(item => (item.code == '201' || item.code == '202'));
    },
    handleExceed1(res) {
      if (res && res.msg.flag === 0) {
        this.$set(this.upload201, 'typeName', '承诺函')
        this.$set(this.upload201, 'name', res.biz.name)
        this.$set(this.upload201, 'code', '201')
        this.$set(this.upload201, 'path', res.biz.path)
        this.$set(this.upload201, 'url', res.biz.url)
        this.$set(this.upload201, 'time', getNowDate())
      }
    },
    beforeAvatarUpload1(rawFile) {
      if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'application/pdf"') {
        this.$message.error('请上传jpg、png、PDF格式的文件')
        return false
      } else if (rawFile.size / 1024 / 1024 > 5) {
        this.$message.error('文件大小不超过5MB!')
        return false
      }
      return true
    },
    handleExceed2(res) {
      if (res && res.msg.flag === 0) {
        this.$set(this.upload202, 'typeName', '任命书')
        this.$set(this.upload202, 'name', res.biz.name)
        this.$set(this.upload202, 'code', '202')
        this.$set(this.upload202, 'path', res.biz.path)
        this.$set(this.upload202, 'url', res.biz.url)
        this.$set(this.upload202, 'time', getNowDate())
      }
    },
    beforeAvatarUpload2(rawFile) {
      if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png' && rawFile.type !== 'application/pdf"') {
        this.$message.error('请上传jpg、png、PDF格式的文件')
        return false
      } else if (rawFile.size / 1024 / 1024 > 5) {
        this.$message.error('文件大小不超过5MB!')
        return false
      }
      return true
    },

    async submitUpload1() {
      if (!this.upload201.path) {
        this.$message.error('请选择文件！')
        return
      }
      this.fileList.push({...this.upload201})

    },
    async submitUpload2() {
      if (!this.upload202.path) {
        this.$message.error('请选择文件！')
        return
      }
      this.fileList.push({...this.upload202})
    },
    handleview:function (url){
      window.open(url);
    },
    handleDel:function (oid){
      const self=this;
      let params={organId:self.organ.id,oid:oid}
      self.$axios.delete('/api/customer/organization/register/attestation/file/remove', {params:params})
          .then((res) => {
            self.loading = false;
            if(null != res && null != res.msg && res.msg.flag === 0){
              self.fileList = self.fileList.filter(item => item.oid !== oid);
            }else{
              self.error = {loading: true, message: res.msg.message};
            }
          }).catch(() => {
        self.loading = false;
      });

    },
    //保存数据
    async saveForm() {
      const self = this;
      this.error ={loading: false, message: ''};
      this.$refs.ruleFormRef.validate((valid) =>{
        if (valid) {
          this.loading = true;
          let params={};
          let fileList=[];
          self.fileList.forEach(function (item){
            if(null == item.oid || undefined == item.oid  || '' == item.oid){
              fileList.push(item)
            }

          })
          params = {organId:self.organ.id, files:fileList};
          this.$axios.post('/api/customer/organization/register/certified', params)
              .then((res) => {
                self.loading = false;
                if(null != res && null != res.msg && res.msg.flag === 0){
                  self.reqOrganDetail();
                  self.$message.success({message: res.msg.message});
                }else{
                  self.error = {loading: true, message: res.msg.message};
                }
              }).catch(() => {
            self.loading = false;
          });
        }
      });
    },
    //发出申请
    submitForm() {
      const self=this;
      self.$confirm('确定发起认证申请吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // self.saveForm()
        self.$axios.post('/api/customer/organization/register/certified/'+self.organ.id)
            .then((res) => {
              if(null != res && null != res.msg && res.msg.flag === 0){
                self.$message.success({message: res.msg.message});
              }else{
                self.error = {message: res.msg.message};
              }
            }).catch(() => {
        });
      }).catch(() => {
      });
    },
    //重新选择
    reselect:function (){
      const self=this;
      self.$confirm('点击重新选择认证，您之前自评数据将丢失！确认要重新选择认证吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        self.$axios.post('/api/customer/organization/register/type/certified/again/'+self.organ.id)
            .then((res) => {
              if(null != res && null != res.msg && res.msg.flag === 0){
                location.reload();
                self.$router.push('/auth/cert/type')
              }else{
                self.error = {message: res.msg.message};
              }
            }).catch(() => {
        });
      }).catch(() => {
      });
    },
  },
}
</script>

<style lang="scss">
.certificate-collapse {
  width: 100%;

  .el-collapse-item__header {
    background: #508caa;
    color: #fff;
    font-size: 16px;
    padding-left: 10px;
  }

  .el-collapse-item__content {
    padding-bottom: 10px;
  }

  .certificate-collapse-li {
    background: #d5f6d8;
    border-bottom: 3px solid #fff;
    line-height: 28px;
    padding: 8px 10px;
    word-wrap: break-word;
    font-size: 14px;

    & > div {
      font-weight: bold;
    }
  }

  .certificate-collapse-btn {
    width: 120px;
    text-align: center;
  }
}

.el-divider__text {
  background-color: #d5f6d8;
  font-size: 25px;
}

.line {
  width: 100%;
  border: 1px solid #DDDFE6
}

.text-title {
  color: #000;
  font-weight: bold
}
</style>